import {FragmentType} from "src/config/constants";

export interface FragmentSettings {
  knowledgeBaseId: number;
  id?: number;
  lang: string;
  title: string;
  settings: Settings;
  type: FragmentType;
  deleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export enum FragmentMode {
  Settings = "settings",
  Iframe = "iframe",
}

export interface QuestionAccordionSettings {
  limit: number;
  questions: number[];
  orderByViews: boolean;
}

export interface FormSettings {
  formId: number;
}

export type Settings = QuestionAccordionSettings | FormSettings;

import {AxiosResponse} from "axios";
import {Assistant, Atlas, Backend} from ".";
import {Config} from "../config/config";
import {Auth, Category, Question} from "../interfaces";
import {Response} from "../interfaces/response";
import {SSOLoginResponse} from "../interfaces/auth";
import {KBSettings, SiteSettings} from "../interfaces/settings";
import {User} from "../interfaces/user";
import {KnowledgeBase} from "src/interfaces/knowledgeBase";
import {Customer} from "src/interfaces/customer";
import {Enquiry} from "src/interfaces/enquiry";
import {Mail} from "src/interfaces/mail";
import {Attachment} from "src/interfaces/questions";
import {Release} from "src/interfaces/changelog";
import {Language} from "src/interfaces/languages";
import {AssistantRequest, AssistantResponse, VectorStoreRequest, VectorStoreResponse} from "src/interfaces/openai";
import {AssistantChatFeedback, JSONValue} from "src/interfaces/assistant";
import {Activity} from "src/interfaces/activity";
import {DashboardTimePeriod} from "src/config/constants";
import {FragmentSettings} from "src/interfaces/fragments";

const API_BASEPATH = Config.basepath;

export async function LoginUser(user: User) {
  return Backend.post<Auth>(`${Config.host}${API_BASEPATH}/login`, user);
}

export async function LoginUserBySSOToken(token: string) {
  return Backend.post<SSOLoginResponse>(`${Config.host}${API_BASEPATH}/login/sso`, {token});
}

export async function GetKnowledgeBases(): Promise<Response<KnowledgeBase[]>> {
  const response = await Backend.get<Response<KnowledgeBase[]>>(`${Config.host}${API_BASEPATH}/knowledge_bases`);
  return response.data;
}

export async function GetKnowledgeBase(uniqueId: string): Promise<Response<KnowledgeBase>> {
  const response = await Backend.get<Response<KnowledgeBase>>(
    `${Config.host}${API_BASEPATH}/knowledge_base?uniqueId=${uniqueId}`,
  );
  return response.data;
}

export async function CreateKnowledgeBase(knowledgeBase: KnowledgeBase): Promise<Response<KnowledgeBase>> {
  const response = await Backend.post<Response<KnowledgeBase>>(
    `${Config.host}${API_BASEPATH}/knowledge_base`,
    knowledgeBase,
  );
  return response.data;
}

export async function UpdateKnowledgeBase(knowledgeBase: KnowledgeBase): Promise<Response<KnowledgeBase>> {
  const response = await Backend.put<Response<KnowledgeBase>>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBase.id}`,
    knowledgeBase,
  );
  return response.data;
}

export async function DeleteKnowledgeBase(knowledgeBaseId: number): Promise<Response<KnowledgeBase>> {
  const response = await Backend.delete<Response<KnowledgeBase>>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}`,
  );
  return response.data;
}

export async function GetCategories(knowledgeBaseId: number): Promise<Category[]> {
  const response = await Backend.get<Response<Category[]>>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/categories`,
  );
  return response.data.data;
}

export async function GetCategory(knowledgeBaseId: number, categoryId: number): Promise<AxiosResponse<Category, any>> {
  return Backend.get<Category>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}`,
  );
}

export async function CreateCategory(knowledgeBaseId: number, category: Category): Promise<Response<Category>> {
  const response = await Backend.post<Response<Category>>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category`,
    category,
  );
  return response.data;
}

export async function DeleteCategory(
  knowledgeBaseId: number,
  categoryId: number,
): Promise<AxiosResponse<Category, any>> {
  return Backend.delete<Category>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}`,
  );
}

export async function UpdateCategoryViews(
  knowledgeBaseId: number,
  categoryId: number,
  languageId: number,
): Promise<AxiosResponse<{message: string}, any>> {
  return Backend.put<{message: string}>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}/lang/${languageId}/views`,
  );
}

export async function UpdateCategory(
  knowledgeBaseId: number,
  categoryId: number,
  category: Category,
): Promise<AxiosResponse<Category, any>> {
  return Backend.put<Category>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}`,
    category,
  );
}

export async function GetQuestions(knowledgeBaseId: number, questionLangIds?: number[]): Promise<Question[]> {
  const response = await Backend.get<Response<Question[]>>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/questions${
      questionLangIds ? `?ids=${questionLangIds.join(",")}` : ""
    }`,
  );
  return response.data.data;
}

export async function SearchQuestions(searchText: string): Promise<AxiosResponse<Question[], any>> {
  return Backend.get<Question[]>(`${Config.host}${API_BASEPATH}/questions?text=${searchText}`);
}

export async function GetQuestion(
  knowledgeBaseId: number,
  categoryId: number,
  questionId: number,
): Promise<AxiosResponse<Question, any>> {
  return Backend.get<Question>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/${categoryId}/question/${questionId}`,
  );
}

export async function PostQuestion(
  knowledgeBaseId: number,
  categoryId: number,
  question: Question,
): Promise<Response<Question>> {
  const response = await Backend.post<Response<Question>>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}/question`,
    question,
  );

  return response.data;
}

export async function PostAttachments(
  files: File[],
  data: Attachment[],
  knowledgeBaseId?: number,
  categoryId?: number,
  questionId: number = 0,
): Promise<Response<Attachment[]>> {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("attachments", file);
  });
  formData.append("data", JSON.stringify(data));

  const response = await Backend.post<Response<Attachment[]>>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}/question/${questionId}/attachments`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response.data;
}

export async function UpdateQuestion(
  knowledgeBaseId: number,
  categoryId: number,
  questionId: number,
  question: Question,
): Promise<AxiosResponse<Question, any>> {
  return Backend.put<Question>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}/question/${questionId}`,
    question,
  );
}

export async function UpdateQuestionViews(
  knowledgeBaseId: number,
  categoryId: number,
  questionId: number,
  languageId: number,
): Promise<AxiosResponse<Question, any>> {
  return Backend.put<Question>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}/question/${questionId}/lang/${languageId}/views`,
  );
}

export async function UpvoteQuestion(
  knowledgeBaseId: number,
  categoryId: number,
  questionId: number,
  languageId: number,
): Promise<AxiosResponse<Question, any>> {
  return Backend.put<Question>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}/question/${questionId}/lang/${languageId}/upvote`,
  );
}

export async function DownvoteQuestion(
  knowledgeBaseId: number,
  categoryId: number,
  questionId: number,
  languageId: number,
): Promise<AxiosResponse<Question, any>> {
  return Backend.put<Question>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}/question/${questionId}/lang/${languageId}/downvote`,
  );
}

export async function GetQuestionsByCategory(knowledgeBaseId: number, categoryId: number): Promise<Question[]> {
  const response = await Backend.get<Response<Question[]>>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}/questions`,
  );
  return response.data.data;
}

export async function GetEnquiries(): Promise<AxiosResponse<Enquiry[], any>> {
  const response = await Backend.get(`${Config.host}${API_BASEPATH}/enquiries`);
  return response.data;
}

export async function GetEnquiry(id: number): Promise<AxiosResponse<Enquiry, any>> {
  return Backend.get(`${Config.host}${API_BASEPATH}/enquiry/${id}`);
}

export async function ResolveEnquiry(id: number): Promise<AxiosResponse<Enquiry, any>> {
  return Backend.put(`${Config.host}${API_BASEPATH}/enquiry/${id}/resolve`);
}

export async function CreateEnquiry(enquiry: Enquiry): Promise<AxiosResponse<Enquiry, any>> {
  return Backend.post(`${Config.host}${API_BASEPATH}/enquiry`, enquiry);
}

export async function DeleteQuestion(
  knowledgeBaseId: number,
  categoryId: number,
  questionId: number,
): Promise<AxiosResponse<Question, any>> {
  return Backend.delete<Question>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}/question/${questionId}`,
  );
}

export async function BookmarkQuestion(
  questionId: number,
  languageId: number,
  knowledgeBaseId: number,
  categoryId: number,
): Promise<AxiosResponse<Question, any>> {
  return Backend.put<Question>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}/lang/${languageId}/question/${questionId}/lang/${languageId}/bookmark`,
  );
}

export async function UnbookmarkQuestion(
  questionId: number,
  languageId: number,
  knowledgeBaseId: number,
  categoryId: number,
): Promise<AxiosResponse<Question, any>> {
  return Backend.put<Question>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/category/${categoryId}/lang/${languageId}/question/${questionId}/lang/${languageId}/unbookmark`,
  );
}

export async function GetKBSettings(knowledgeBaseId: number): Promise<KBSettings> {
  const response = await Backend.get<Response<KBSettings>>(
    `${Config.host}${API_BASEPATH}/settings/knowledge_base/${knowledgeBaseId}`,
  );
  return response.data.data;
}

export async function GetKBSettingsByUniqueId(uniqueId: string): Promise<KBSettings> {
  const response = await Backend.get<Response<KBSettings>>(
    `${Config.host}${API_BASEPATH}/settings?uniqueId=${uniqueId}`,
  );
  return response.data.data;
}

export async function UpdateKBSettings(KBSettings: KBSettings, knowledgeBaseId: number = 1): Promise<KBSettings> {
  const response = await Backend.put<Response<KBSettings>>(
    `${Config.host}${API_BASEPATH}/settings/knowledge_base/${knowledgeBaseId}`,
    KBSettings,
  );
  return response.data.data;
}

export async function GetSiteSettings(siteId: number): Promise<SiteSettings> {
  const response = await Backend.get<Response<SiteSettings>>(`${Config.host}${API_BASEPATH}/settings/site/${siteId}`);
  return response.data.data;
}

export async function UpdateSiteSettings(siteSettings: SiteSettings, siteId: number): Promise<SiteSettings> {
  const response = await Backend.put<Response<SiteSettings>>(
    `${Config.host}${API_BASEPATH}/settings/site/${siteId}`,
    siteSettings,
  );
  return response.data.data;
}

export async function GetUsers(): Promise<User[]> {
  const response = await Backend.get<Response<User[]>>(`${Config.host}${API_BASEPATH}/users`);
  return response.data.data;
}

export async function CreateUser(user: User): Promise<AxiosResponse<User[], any>> {
  return Backend.post(`${Config.host}${API_BASEPATH}/user`, user);
}

export async function UpdateUser(user: User): Promise<Response<User>> {
  return Backend.put(`${Config.host}${API_BASEPATH}/user/${user.id}`, user);
}

export async function RemoveUser(user: User): Promise<AxiosResponse<User, any>> {
  return Backend.delete(`${Config.host}${API_BASEPATH}/user/${user.id}`);
}

export async function PostMail(mail: Mail): Promise<AxiosResponse<any, any>> {
  const response = await Backend.post<any>(`${Config.host}${API_BASEPATH}/mail`, mail);
  return response;
}

export async function ValidateToken(): Promise<Response<Auth>> {
  const response = await Backend.get<Response<Auth>>(`${Config.host}${API_BASEPATH}/validate`);
  return response.data;
}

export async function GetCustomerBySiteId(siteId: number): Promise<AxiosResponse<Customer>> {
  const response = await Backend.get<any>(`${Config.host}${API_BASEPATH}/customer/site_id/${siteId}`);
  return response.data;
}

export async function GetCustomers(): Promise<AxiosResponse<Customer[]>> {
  const response = await Backend.get<any>(`${Config.host}${API_BASEPATH}/customers`);
  return response.data;
}

export async function CreateCustomer(customer: Customer): Promise<AxiosResponse<any>> {
  const response = await Backend.post<any>(`${Config.host}${API_BASEPATH}/customer`, customer);
  return response.data;
}

export async function DeleteCustomer(id: number): Promise<AxiosResponse<Customer>> {
  const response = await Backend.delete<any>(`${Config.host}${API_BASEPATH}/customer/${id}`);
  return response.data;
}

export async function UpdateCustomer(id: number, customer: Customer): Promise<AxiosResponse<Customer>> {
  const response = await Backend.put<any>(`${Config.host}${API_BASEPATH}/customer/${id}`, customer);
  return response.data;
}

export async function GetWidgetForm(formId: number): Promise<AxiosResponse<any>> {
  const response = await Atlas.get(`${Config.atlas}/form_website/${formId}/form`);
  return response.data;
}

export async function PostWidgetForm(formId: number, form: FormData): Promise<AxiosResponse<any>> {
  const response = await Atlas.post(`${Config.atlas}/form_website/${formId}/submission`, form);
  return response.data;
}

export async function GetLanguages(): Promise<AxiosResponse<Language[]>> {
  const response = await Backend.get<AxiosResponse<Language[]>>(`${Config.host}${API_BASEPATH}/languages`);
  return response.data;
}

export async function GetReleases(): Promise<AxiosResponse<Release[]>> {
  const response = await Backend.get<AxiosResponse<Release[]>>(`${Config.host}${API_BASEPATH}/releases`);
  return response.data;
}

export async function CreateThread(knowledgeBaseId: number): Promise<AxiosResponse<any>> {
  const response = await Assistant.get(`${Config.assistantApi}/api/v1/knowledge_base/${knowledgeBaseId}/thread`);
  return response.data;
}

export async function PostMessageToAssistant(
  knowledgeBaseId: number,
  assistantId: string,
  message: string,
  threadId: string,
): Promise<AxiosResponse<any>> {
  const response = await Assistant.post(
    `${Config.assistantApi}/api/v1/knowledge_base/${knowledgeBaseId}/assistant/message`,
    {
      assistant_id: assistantId,
      message: message,
      thread_id: threadId,
    },
  );
  return response.data;
}

export async function GetAssistantMessages(knowledgeBaseId: number, threadId: string): Promise<AxiosResponse<any>> {
  const response = await Assistant.get(
    `${Config.assistantApi}/api/v1/knowledge_base/${knowledgeBaseId}/assistant/thread/${threadId}/messages`,
  );

  return response.data;
}

export async function GetAssistantSettings(knowledgeBaseId: number): Promise<AxiosResponse<any>> {
  const response = await Assistant.get(
    `${Config.assistantApi}/api/v1/knowledge_base/${knowledgeBaseId}/assistant/settings`,
  );
  return response.data;
}

export async function UpdateAssistant(
  knowledgeBaseId: number,
  assistantId: string,
  name: string,
  instructions: string,
  model: string,
) {
  const response = await Assistant.post(
    `${Config.assistantApi}/api/v1/knowledge_base/${knowledgeBaseId}/assistant/${assistantId}/update`,
    {
      name: name,
      instructions: instructions,
      model: model,
    },
  );
  return response.data;
}

export async function PostFileToAssistant(
  knowledgeBaseId: number,
  vectorStoreId: string,
  file: File,
): Promise<AxiosResponse<any>> {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("file_type", file.type);

  const response = await Assistant.post(
    `${Config.assistantApi}/api/v1/knowledge_base/${knowledgeBaseId}/vs_id/${vectorStoreId}/upload_file`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response.data;
}

export async function DeleteAssistantResource(
  knowledgeBaseId: number,
  vectorStoreId: string,
  resourceId: string,
): Promise<AxiosResponse<any>> {
  const response = await Assistant.delete(
    `${Config.assistantApi}/api/v1/knowledge_base/${knowledgeBaseId}/vector_store/${vectorStoreId}/file/${resourceId}`,
  );
  return response.data;
}

export async function UpdateAssistantResource(
  knowledgeBaseId: number,
  vectorStoreId: string,
  resourceId: string,
  file: File,
): Promise<AxiosResponse<any>> {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("file_type", file.type);

  const response = await Assistant.put(
    `${Config.assistantApi}/api/v1/knowledge_base/${knowledgeBaseId}/vs_id/${vectorStoreId}/file/${resourceId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response.data;
}

export async function GetAssistantReply(
  knowledgeBaseId: number,
  assistantId: string,
  question: string,
): Promise<AxiosResponse<any>> {
  const response = await Assistant.post(
    `${Config.assistantApi}/api/v1/knowledge_base/${knowledgeBaseId}/assistant/${assistantId}/reply`,
    {
      question: question,
    },
  );
  return response.data;
}

export async function CreateAssistant(request: AssistantRequest): Promise<AxiosResponse<AssistantResponse>> {
  const response = await Assistant.post(
    `${Config.assistantApi}/api/v1/knowledge_base/${request.knowledgeBaseId}/assistant`,
    {
      name: request.name,
      model: request.model,
      instructions: request.instructions,
      tools: [{type: "file_search"}],
      vectorIds: request.vectorIds,
    },
  );
  return response.data;
}

export async function CreateVectorStore(request: VectorStoreRequest): Promise<AxiosResponse<VectorStoreResponse>> {
  const response = await Assistant.post(
    `${Config.assistantApi}/api/v1/knowledge_base/${request.knowledgeBaseId}/vector_store`,
    {
      name: request.name,
    },
  );
  return response.data;
}

export async function PostAssistantFeedback(
  knowledgeBaseId: number,
  assistantId: string,
  threadId: string,
  feedback: AssistantChatFeedback,
) {
  const response = await Assistant.post(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/assistant/${assistantId}/thread/${threadId}/transcript`,
    feedback,
  );
  return response.data;
}

export async function PostWidgetFormSubmission(
  knowledgeBaseId: number,
  formId: number,
  form: JSONValue,
): Promise<AxiosResponse<any>> {
  const response = await Backend.post(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/form/${formId}`,
    form,
  );
  return response.data;
}

export async function GetWidgetFormSubmissions(knowledgeBaseId: number): Promise<AxiosResponse<any>> {
  const response = await Backend.get(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/form/submissions`,
  );
  return response.data;
}

export async function GetAssistantFeedbackTranscripts(
  knowledgeBaseId: number,
  assistantId: string,
): Promise<AxiosResponse<any>> {
  const response = await Backend.get(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/assistant/${assistantId}/transcripts`,
  );
  return response.data;
}

export async function GetActivities(
  knowledgeBaseId: number,
  timePeriod: DashboardTimePeriod,
  customTimePeriods?: {start: string; end: string},
): Promise<AxiosResponse<Activity[]>> {
  const response = await Backend.get(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/activities?duration=${timePeriod}${
      customTimePeriods ? `&start_time=${customTimePeriods.start}&end_time=${customTimePeriods.end}` : ""
    }`,
  );
  return response.data;
}

export async function CreateFragment(
  knowledgeBaseId: number,
  fragment: FragmentSettings,
): Promise<AxiosResponse<FragmentSettings>> {
  const response = await Backend.post(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/fragment`,
    fragment,
  );
  return response.data;
}

export async function GetFragments(knowledgeBaseId: number): Promise<AxiosResponse<FragmentSettings[]>> {
  const response = await Backend.get<AxiosResponse<FragmentSettings[]>>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/fragments`,
  );
  return response.data;
}

export async function UpdateFragment(
  knowledgeBaseId: number,
  fragmentId: number,
  fragment: FragmentSettings,
): Promise<AxiosResponse<FragmentSettings>> {
  const response = await Backend.put(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/fragment/${fragmentId}`,
    fragment,
  );
  return response.data;
}

export async function DeleteFragment(knowledgeBaseId: number, fragmentId: number): Promise<AxiosResponse<any>> {
  const response = await Backend.delete(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/fragment/${fragmentId}`,
  );
  return response.data;
}

export async function GetFragmentSettings(
  knowledgeBaseId: number,
  fragmentId: number,
): Promise<AxiosResponse<FragmentSettings>> {
  const response = await Backend.get<AxiosResponse<FragmentSettings>>(
    `${Config.host}${API_BASEPATH}/knowledge_base/${knowledgeBaseId}/fragment/${fragmentId}`,
  );
  return response.data;
}

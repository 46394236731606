import {useState, useEffect} from "react";
import {Question} from "src/interfaces";
import {FragmentSettings, QuestionAccordionSettings} from "src/interfaces/fragments";
import {GetQuestions} from "src/services";
import {GetKnowledgeBase, GetFragmentSettings} from "src/services/backend";

export const useQuestionAccordionFragment = (uniqueId?: string, fragmentId?: string) => {
  const [knowledgeBaseId, setKnowledgeBaseId] = useState<number | null>(null);
  const [settings, setSettings] = useState<FragmentSettings | null>(null);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!uniqueId || !fragmentId) return;

    const fetchFragmentData = async () => {
      try {
        setIsLoading(true);
        const knowledgeBaseResponse = await GetKnowledgeBase(uniqueId);
        setKnowledgeBaseId(knowledgeBaseResponse.data.id);
        const {data: fragmentSettings} = await GetFragmentSettings(knowledgeBaseResponse.data.id, parseInt(fragmentId));
        setSettings(fragmentSettings);

        const questionData = await GetQuestions(
          knowledgeBaseResponse.data.id,
          (fragmentSettings.settings as QuestionAccordionSettings).questions,
        );

        setQuestions(questionData);

        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err : new Error("Failed to fetch fragment data"));
      } finally {
        setIsLoading(false);
      }
    };

    fetchFragmentData();
  }, [uniqueId, fragmentId]);

  return {
    knowledgeBaseId,
    settings,
    questions,
    isLoading,
    error,
  };
};

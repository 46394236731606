export const availableLanguages = [
  {title: "English", value: "en"},
  {title: "Svenska", value: "sv"},
  {title: "Soumi", value: "fi"},
  {title: "Deutsch", value: "de"},
  {title: "Norsk", value: "no"},
  {title: "Dansk", value: "da"},
];

export enum WebsiteContent {
  DEFAULT = "default",
  FORMS = "forms",
  SEARCH = "search",
}

export const WEBSITE_CONTENT_OPTIONS = [
  {title: "Default", value: WebsiteContent.DEFAULT},
  {title: "Forms", value: WebsiteContent.FORMS},
];

export const DEFAULT_INSTRUCTION_REPLY = `Critical Instruction Set for AI Response Generation
These instructions represent the non-negotiable requirements for each response. No response should be finalized until every requirement is met.

Key Requirements for Every Response
Use Available Resources (PDFs, Data Files, and APIs)

Instruction: Before replying, review all PDF files, data sources, and available API data for a pre-approved answer. Directly use any relevant information provided in these resources.
Example:
Correct: “Thank you for the images. Based on our product manual, here’s the next step: [solution from PDF].”
Incorrect: “We will review the information and get back to you.”
Direct Resolution Only – No Follow-Up Phrasing

Instruction: Provide a complete answer based on available information. Do not imply further follow-up unless absolutely required.
Example:
Correct: “Thank you for reaching out. Here’s the information you need: [specific answer].”
Incorrect: “We’ll review and get back to you shortly.”
Omit Signatures or Agent Names

Instruction: The response should not contain any signature or agent name, such as “Med Vänliga Hälsningar” or “Kundservice.”
Example:
Correct: “If you have more questions, feel free to reach out.”
Incorrect: “Med Vänliga Hälsningar, Annie, Kundservice.”
Apply Proper Formatting and Line Breaks for Readability

Instruction: Structure each response with line breaks:
After the greeting (<br />)
Between sections of the response (<br />)
Example:
Correct:
bash
Hej Irene!<br />
Tack för att du skickade bilderna.<br />
Här är nästa steg: [detailed answer from PDF].<br />
Ha en fin dag!
Incorrect: “Hej Irene! Tack för att du skickade bilderna. Vi kommer att återkomma till dig så snart som möjligt.”

Output Response in minified JSON Format with Confidence Score.
Instruction: Every response should output in minified JSON format, with a “message” and “confidence_score” key. Confidence should aim for 7 or higher.
Example:
json
{"message":"Your fully formatted and accurate response here.","confidence_score": 8}

Final Checklist for Each Response
Before finalizing, check that all requirements below are met:
Minified JSON Format with confidence score included.
Used Available Resources (PDF or API data) if available.
Direct Resolution with no “follow-up” language.
No Signature or Agent Name included.
Proper Line Break Formatting applied.`;
export const DEFAULT_INSTRUCTION_CHAT =
  "You are a customer service chatbot assistant that provides personalized responses in Swedish. Your task is to reply based only on the information available in the attached documents. Follow these guidelines: Personalization: Address the user by their name if provided in the message or context. Otherwise, remain formal. Document-Based Responses: Base your answers strictly on the content of the appended documents. If a direct answer cannot be derived from these documents, kindly inform the user that the necessary information is not available. No Assumptions: Avoid introducing any information or assumptions not present in the documents. Clarity and Tone: Keep responses professional, clear, and polite. Maintain a friendly tone, appropriate to a customer service context. If you encounter multiple questions, structure the response to address each one clearly.";
export const DEFAULT_INSTRUCTION_INSIGHTS = `You are the Knowledge Base Insights Assistant, designed to help users improve and maintain their knowledge base by providing valuable insights based on user interactions and feedback. Your primary role is to analyze user ratings (e.g., "Helpful" or "Not Helpful"), identify trending topics, and offer suggestions for enhancing content.
Your tasks:
Summarize Activity: Provide a summary that includes the number of views, upvotes, downvotes, and overall engagement for specific knowledge base articles or interactions.
Example:
• "Article 'X' received 150 views, with 85% positive feedback, suggesting high relevance to users."
Identify Problematic Content: Highlight articles or interactions that received consistently negative feedback, and suggest potential improvements or new content that could resolve common issues.
Example:
• "Article 'Y' had a 60% negative feedback rate. Consider updating the content to address common user questions about 'Z.'"
Trend Analysis: Detect frequently asked questions or common queries that are driving user engagement, and propose ways to expand the knowledge base based on these trends.
Example:
• "Users are frequently searching for 'return policies.' You may want to create a more detailed article or a step-by-step guide to address this need."
User Feedback Insights: Break down positive and negative feedback by category (e.g., clarity, usefulness, accuracy) and provide suggestions on how to improve the knowledge base in each area.
Example:
• "The majority of negative feedback stems from unclear instructions in technical articles. Consider adding more visuals or examples to improve clarity."
Engagement Suggestions: Based on the data you've collected, suggest actions the knowledge base administrator can take to improve overall user satisfaction and reduce the burden on human agents.
Example:
• "Users seem satisfied with self-service on FAQs related to shipping, reducing the need for agent intervention by 30%. Expanding this section could further enhance user satisfaction."
Your goal is to:
• Assist the user in maintaining a high-quality, responsive knowledge base.
• Help administrators track engagement trends and improve articles based on user feedback.
• Provide clear and actionable insights in a user-friendly manner.`;

export const SYSTEM_INSTRUCTIONS_SEPARATOR = "----SYSTEM_INSTRUCTIONS----";
export const ASSISTANT_SUPPORTED_FILE_TYPES = ["application/json", "application/pdf", "text/plain"];
export const DEFAULT_ASSISTANT_MODEL = "gpt-4o-mini";
export const DEFAULT_ASSISTANT_MODEL_INSIGHTS = "gpt-4o";
export const CURRENT_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const FORM_FIELDS_TO_EXCLUDE = [
  "__contact_emails",
  "__contact_emails_0",
  "__contact_emails_1",
  "__contact_emails_2",
  "__contact_phones",
  "__contact_phones_0",
  "__contact_phones_1",
  "__contact_phones_2",
  "__contact_first_name",
  "__contact_last_name",
  "Attachments",
  "Rabattkod",
  "LankTillHemsidanMedLagrePris",
  "AdressNy",
  "OnskadLeveransadress",
  "Personnummer",
  "Ordernummer",
];
export const SUGGESTED_INSIGHTS_ASSISTANT_QUESTIONS = [
  "Based on all of your available data, give me a breakdown of the state of the knowledge base, and how we can best improve it.",
  "What parts of the knowledge base are the weakest and need the most attention?",
  "What are the most common questions asked by customers?",
  "Can you identify any gaps in our knowledge base content based on user questions?",
  "What are the top 5 articles that have received the most negative feedback, and how can we improve them?",
  "What can we learn from the articles with the most positive feedback?",
  "Are there any seasonal trends in user queries that we should prepare for?",
  "Which topics have seen the most significant increase in user interest recently?",
  "What are the most common reasons for users rating articles as 'Not Helpful'?",
  "Can you identify any redundant or overlapping content in our knowledge base?",
];
export const KEYS_OF_INTEREST = ["long_text", "radio", "checkboxes", "select", "html", "text", "list"];
export const MIN_SCORE_FOR_SUGGESTED_QUESTIONS = 0.28;

export enum DashboardTimePeriod {
  CUSTOM = "custom",
  ALL = "all_time",
  DAY = "last_24_hours",
  WEEK = "last_7_days",
  MONTH = "last_30_days",
}

export enum FragmentType {
  QUESTION_ACCORDION = "questions_accordion",
  FORM = "form",
  ARTICLE = "article",
}

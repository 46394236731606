import {useEffect} from "react";

export const useIframeResize = (elementId: string) => {
  const sendHeightToParent = () => {
    try {
      const contentDiv = document.getElementById(elementId);
      if (!contentDiv) return;

      requestAnimationFrame(() => {
        const contentHeight = contentDiv.offsetHeight;
        window.parent.postMessage(
          {
            type: `resize-${elementId}`,
            height: contentHeight,
          },
          "*",
        );
      });
    } catch (error) {
      console.error("Error sending height:", error);
    }
  };

  useEffect(() => {
    sendHeightToParent();

    const resizeObserver = new ResizeObserver(() => {
      sendHeightToParent();
    });

    const contentDiv = document.getElementById(elementId);
    if (contentDiv) {
      resizeObserver.observe(contentDiv);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [elementId, sendHeightToParent]);

  return {sendHeightToParent};
};
